import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useSubmitClearMutation,
	useSubmitFirstStepMutation,
} from "../../../data/mutations/registration";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { CustomButton } from "../../common/buttons/index";
import { CustomInput } from "../../common/inputs";
import RegistrationCheckboxes from "./registration.checkboxes";
import { TCheckbox, TCheckboxes, TMappedCheckboxes } from "../../../types/types";
import { ROUTES } from "../../../types/routes";
import { ParamContext } from "../../../data/param.provider";
import { ConfirmRegistrationModal } from "../../common/modals";

const RegistrationPersonalLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { wState } = useContext(ParamContext);

	const [email, setEmail] = useState<string>("");
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [data, setData] = useState<TCheckboxes>(
		sessionStorage.getItem("checkBoxesText")
			? JSON.parse(sessionStorage.getItem("checkBoxesText") as string)
			: undefined
	);

	const { mutateAsync, isPending, isError, mutationError } = useSubmitFirstStepMutation(
		navigate,
		Boolean(sessionStorage.getItem("isEmailVerified"))
	);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	useEffect(() => {
		setIsLoading(true);
		const sessionCheckboxesBack = sessionStorage.getItem("checkBoxesText");
		const sessionEmail = sessionStorage.getItem("email");
		const checkBoxes = sessionStorage.getItem("checkBoxes");
		const wStateInfo = JSON.parse(sessionStorage.getItem("wStateInfo") as string);
		if (sessionCheckboxesBack) {
			setData(JSON.parse(sessionCheckboxesBack));
		}
		if (wStateInfo) {
			setEmail(wStateInfo.initEmail);
			setIsEmailRequired(wStateInfo.initEmail?.length > 0);
			if(wStateInfo?.status?.toLocaleLowerCase() === 'done') {
				navigate(ROUTES.REDIRECT.INDEX);
			}
		}
		if (checkBoxes) {
			setIsChecked(JSON.parse(checkBoxes).checkBoxes);
		}
		if (sessionEmail && sessionEmail.length > 0) {
			setEmail(sessionEmail);
			setIsEmailRequired(true);
		}
		setIsLoading(false);
	}, []);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const { mutateClearAsync, isClearLoading } = useSubmitClearMutation(navigate);

	const [isChecked, setIsChecked] = useState<boolean[]>([false, false, false]);

	const buttonRef = useRef(null);

	if (isLoading) {
		return <></>;
	}

	const handleIsConfirmModalOpen = () => {
		setIsConfirmModalOpen(!isConfirmModalOpen);
	};

	const handleBackClick = async () => {
		sessionStorage.clear();
		await mutateClearAsync();
		navigate(ROUTES.LOGIN.INDEX);
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setFakeError(false);
		setIsEmailRequired(e?.target?.value?.length > 0);
	};

	const handleEmailClear = () => {
		setEmail("");
		setFakeError(false);
		setIsEmailRequired(false);
	};

	const handleIsChecked = () => {
		return data?.checkBoxes.some(
			(checkbox, idx) =>
				checkbox.isRequired && checkbox.isRequired != isChecked[idx]
		);
	};

	const handleSubmit = async () => {
		sessionStorage.setItem("email", email);
		sessionStorage.setItem(
			"checkBoxes",
			JSON.stringify({
				checkBoxes: isChecked,
			})
		);
		await mutateAsync({
			email: email,
			checkBoxes: data?.checkBoxes
				.filter((checkbox: TCheckbox) => checkbox.checkBoxId != "cookie")
				.map(
					//eslint-disable-next-line
					(checkbox: any, idx: number) =>
						(checkbox = {
							checkBoxId: checkbox.checkBoxId,
							isSelected: isChecked[idx],
						})
				) as TMappedCheckboxes,
			isEmailVerified: Boolean(
				//eslint-disable-next-line
				sessionStorage.getItem("isEmailVerified") as any
			),
			wState: wState,
			navigate: navigate,
		});
	};

	return (
		<>
			<div className={"component"}>
				<div className={"innerComponent"}>
					<ConfirmRegistrationModal
						isLoading={isClearLoading}
						isModalOpen={isConfirmModalOpen}
						handleIsModalOpen={handleIsConfirmModalOpen}
						handleConfirm={handleBackClick}
					/>
					<h1 className={"welcomeText"}>{t("registrationWelcomeText")}</h1>
					<CustomInput
						isError={fakeError}
						isRequired={isEmailRequired}
						type={"email"}
						id={"email"}
						label={t("registrationProvideEmail")}
						value={email}
						onChange={handleEmailChange}
						className={"registrationInput"}
						onClearClick={handleEmailClear}
						inputPropsRef={buttonRef}
					/>
					<RegistrationCheckboxes
						isError={false}
						setIsChecked={setIsChecked}
						checked={isChecked}
						checkBoxes={data}
					/>
					{fakeError ? (
						<h1 className={"registrationEmailError"}>
							{
								(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					<CustomButton
						buttonRef={buttonRef}
						text={t("registrationStart") as string}
						type={"submit"}
						onClick={handleSubmit}
						className={"startButton"}
						isDisabled={
							handleIsChecked() ||
							!new RegExp(
								/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
							).test(email)
						}
						isLoading={isPending}
					/>
					<CustomButton
						isText={true}
						onClick={handleIsConfirmModalOpen}
						className={"registerNowButton"}
						text={t("loginNow") as string}
					/>
				</div>
			</div>
		</>
	);
};

export default RegistrationPersonalLayout;

import { useMutation } from "@tanstack/react-query";
import { ROUTES } from "../../types/routes";
import { TSubmitVerificationBody } from "../../types/types";
import { fetchVerificationSubmit } from "../../api/verification";
import { fetchSubmitClearTest } from "../../api/registration";

export function useVerificationSubmitMutation(navigate: (path: string) => void) {
	const { mutateAsync, isError, error, isPending } = useMutation({
		mutationKey: ["submitUserData"],
		mutationFn: (data: TSubmitVerificationBody) => fetchVerificationSubmit(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === "registrationEulaEmailIdentityPageId") {
				navigate(ROUTES.REGISTRATION.INDEX);
				return;
			}
			if (data.nextPageId === "registrationSamsubPageId") {
				navigate(ROUTES.VERIFICATION.SUMSUB);
				return;
			}
		},
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending,
	};
}

export function useSumsubClearMutation(navigate: (path: string) => void) {
	const { mutateAsync, isError, error, isPending } = useMutation({
		mutationKey: ["sumsubClear"],
		mutationFn: () => fetchSubmitClearTest(),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.INDEX);
		},
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending,
	};
}

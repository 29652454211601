import {
	ChangeEvent,
	ReactElement,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomInput } from '../common/inputs';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../common/buttons';
import { useLoginWithoutTwoFAMutation } from '../../data/mutations/login';
import { ParamContext } from '../../data/param.provider';
import { TWStateInfo } from '../../types/types';
import { ROUTES } from '../../types/routes';

interface Props {
	data: TWStateInfo;
}
const LoginLayout = ({ data }: Props): ReactElement => {
	const { wState } = useContext(ParamContext);

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const [password, setPassword] = useState<string>('');
	const [isPasswordRequired, setIsPasswordRequired] =
		useState<boolean>(false);

	const buttonRef = useRef(null);
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const { isError, login, error, isPending } =
		useLoginWithoutTwoFAMutation(navigate);

	const [fakeError, setFakeError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	useEffect(() => {
		sessionStorage.setItem('wstate', wState);
		const wStateInfo = JSON.parse(
			sessionStorage.getItem('wStateInfo') as string
		);
		if (wStateInfo) {
			setEmail(wStateInfo.initEmail);
			setIsEmailRequired(wStateInfo.initEmail?.length > 0);
			if (wStateInfo?.status?.toLocaleLowerCase() === 'done') {
				navigate(ROUTES.REDIRECT.INDEX);
			}
		}
	}, []);

	const handleRegistrationClick = () => {
		setIsLoading(true);
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		setFakeError(false);
		e.target.value.length > 0
			? setIsEmailRequired(true)
			: setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail('');
		setFakeError(false);
		setIsEmailRequired(false);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setPassword(e.target.value);
		setFakeError(false);
		e.target.value.length > 0
			? setIsPasswordRequired(true)
			: setIsPasswordRequired(false);
	};

	const handlePasswordClearClick = () => {
		setPassword('');
		setFakeError(false);
		setIsPasswordRequired(false);
	};

	const handleLogin = async () => {
		sessionStorage.setItem('email', email);
		sessionStorage.setItem('password', password);
		await login({
			wState: wState,
			email: email,
			password: password,
			f2xUserAgent: data.f2xUserAgent
		});
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<h1 className={'welcomeText'}>{t('loginText')}</h1>
						<CustomInput
							isError={fakeError}
							isRequired={isEmailRequired}
							type={'email'}
							id={'email'}
							label={'Email'}
							value={email}
							onChange={handleEmailChange}
							className={'emailInput'}
							onClearClick={handleEmailClearClick}
							inputPropsRef={buttonRef}
						/>
						<CustomInput
							isError={fakeError}
							isRequired={isPasswordRequired}
							type={'password'}
							id={'password'}
							label={t('passwordText')}
							value={password}
							onChange={handlePasswordChange}
							className={'passwordInput'}
							onClearClick={handlePasswordClearClick}
							inputPropsRef={buttonRef}
							isPassword={true}
						/>
						{fakeError ? (
							<h1 className={'loginErrorText'}>
								{
									(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
								}
							</h1>
						) : (
							''
						)}
						<a
							onClick={() =>
								navigate(ROUTES.LOGIN.PASSWORD_RECOVERY)
							}
						>
							<h1 className={'forgotPasswordText'}>
								{t('forgotPasswordText')}
							</h1>
						</a>
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isPending}
							text={t('loginText') as string}
							type={'submit'}
							onClick={handleLogin}
							className={
								fakeError ? 'loginButtonError' : 'loginButton'
							}
							isDisabled={
								password.length < 6 ||
								!new RegExp(
									/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
								).test(email)
							}
						/>
						<CustomButton
							isText={true}
							isLoading={isLoading}
							onClick={handleRegistrationClick}
							className={'registerNowButton'}
							text={t('registerNow') as string}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginLayout;

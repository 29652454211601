import { useTranslation } from 'react-i18next';
import { TCountry } from '../../../types/types';
import { Backdrop, Fade, Modal } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomInput } from '../inputs';
import { CountryModalItem, OTPModalItem } from './items';
import { WhaleIcon } from '../icons';
import { CustomButton } from '../buttons';

interface CountryModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleItemClick: (i: { code: string; imgUrl: string }) => void;
	countries: TCountry[];
}

const CountryModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	countries
}: CountryModalProps) => {
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState<string>('');
	const [isSearchValueRequired, setIsSearchValueRequired] =
		useState<boolean>(false);

	const [mappedData, setMappedData] = useState<
		{
			code: string;
			name: string;
			link: string;
			phoneCode: string;
			isRestricted: boolean;
		}[]
	>();

	const [modalInset, setModalInset] = useState<string>('');

	useEffect(() => {
		setMappedData(countries);
	}, []);

	useEffect(() => {
		if (window.innerWidth < 480) {
			if (window.innerHeight < 670) {
				setModalInset((window.innerHeight * 425) / 540 + 'px');
				return;
			} else if (window.innerHeight > 670 && window.innerHeight < 916) {
				setModalInset((window.innerHeight * 280) / 684 + 'px');
				return;
			}
			setModalInset('215px');
		} else {
			setModalInset('545px');
		}
	}, [window.innerHeight]);

	useEffect(() => {
		setMappedData(
			countries.filter(
				(item: {
					code: string;
					name: string;
					link: string;
					phoneCode: string;
					isRestricted: boolean;
				}) =>
					item.code
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					item.name
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					item.phoneCode
						.toLowerCase()
						.includes(searchValue.toLowerCase())
			)
		);
	}, [searchValue]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setIsSearchValueRequired(false);
	};

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		setIsSearchValueRequired(e.target.value.length > 0);
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: modalInset,
				inset: 0
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
					sx: {
						opacity: '0 !important'
					}
				}
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponent'}>
					<div className={''}>
						<CustomInput
							className={'modalInput'}
							id={'registrationModalInput'}
							value={searchValue}
							label={t('registrationSearch')}
							isRequired={isSearchValueRequired}
							type={'text'}
							onChange={handleSearchValueChange}
						/>
					</div>
					{mappedData && mappedData?.length > 0 ? (
						<div className={'modalContent'}>
							{mappedData?.map((item) => (
								<CountryModalItem
									key={item.code}
									handleModalOpen={handleIsModalOpen}
									item={item}
									handleItemClick={handleItemClick}
									setSearchValue={setSearchValue}
								/>
							))}
						</div>
					) : (
						<div className={'modalContent-empty'}>
							<div className={'modalEmptyContainer'}>
								<WhaleIcon className={'whaleIcon'} />
								<h1 className={'modalEmptyHeader'}>
									{t('exchangeNotFound')}
								</h1>
								<h1 className={'modalEmptyBottom'}>
									{t('exchangeChangeSettingsText')}
								</h1>
							</div>
						</div>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface OTPModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleItemClick: (i: { key: string; value: string }) => void;
}

const OTPModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick
}: OTPModalProps) => {
	const [mappedData, setMappedData] = useState<
		{
			key: string;
			value: string;
		}[]
	>();

	useEffect(() => {
		setMappedData(
			JSON.parse(sessionStorage.getItem('identityTypes') as string)
		);
	}, []);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
					sx: {
						opacity: '0 !important'
					}
				}
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<div className={'modalContent'}>
						{mappedData?.map((item) => (
							<OTPModalItem
								key={item.key}
								handleModalOpen={handleIsModalOpen}
								item={item}
								handleItemClick={handleItemClick}
							/>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface ConfirmRegistrationModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleConfirm: () => void;
	isLoading: boolean;
	text?: string;
}

const ConfirmRegistrationModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleConfirm,
	isLoading,
	text
}: ConfirmRegistrationModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500
				}
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<h1 className={'confirmEmailText'}>
						{text ? text : t('confirmModalHeader')}
					</h1>
					<div className={'modalContent'}>
						<div className={'confirmButtonContainer'}>
							<CustomButton
								isGrey
								text={t('confirmModalFirstButton') as string}
								type={'submit'}
								onClick={handleIsModalOpen}
								className={'confirmButton'}
							/>
							<CustomButton
								text={t('confirmModalSecondButton') as string}
								type={'submit'}
								onClick={handleConfirm}
								className={'confirmButton'}
								isLoading={isLoading}
								isDisabled={false}
							/>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export { CountryModal, OTPModal, ConfirmRegistrationModal };

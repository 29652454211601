import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { CustomButton } from '../common/buttons';
import { CustomInput } from '../common/inputs';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { ROUTES } from '../../types/routes';
import { useVerificationSubmitMutation } from '../../data/mutations/verification';
import { ParamContext } from '../../data/param.provider';
import { TModifiedCountry } from '../../types/types';
import { ConfirmRegistrationModal, CountryModal } from '../common/modals';
import countries from '../../types/countries/countries.json';
import { useSubmitClearMutation } from '../../data/mutations/registration';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const VerificationLayout = () => {
	const { wState } = useContext(ParamContext);

	const [firstName, setFirstName] = useState<string>('');
	const [isFirstNameRequired, setIsFirstNameRequired] =
		useState<boolean>(false);

	const [lastName, setLastName] = useState<string>('');
	const [isLastNameRequired, setLastNameRequired] = useState<boolean>(false);

	const [middleName, setMiddleName] = useState<string>('');
	const [isMiddleNameRequired, setMiddleNameRequired] =
		useState<boolean>(false);

	const [dateOfBirth, setDateOfBirth] = useState<string>('');
	const [isDateOfBirthRequired, setDateOfBirthRequired] =
		useState<boolean>(false);

	const [country, setCountry] = useState<TModifiedCountry | undefined>(
		undefined
	);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const { mutateAsync, isError, error, isPending } =
		useVerificationSubmitMutation(navigate);

	const [isFirstNameError, setIsFirstNameError] = useState<boolean>(false);

	const [isLastNameError, setIsLastNameError] = useState<boolean>(false);

	const [isDateOfBirthError, setIsDateOfBirthError] =
		useState<boolean>(false);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [isConfirmModalOpen, setIsConfirmModalOpen] =
		useState<boolean>(false);

	const { mutateClearAsync, isClearLoading } =
		useSubmitClearMutation(navigate);

	useEffect(() => {
		if (isError) {
			if (
				isError &&
				(error as any)?.message === 'FirstName is invalid' //eslint-disable-line @typescript-eslint/no-explicit-any
			) {
				setIsFirstNameError(isError);
				return;
			}
			if (
				isError &&
				(error as any)?.message === 'LastName is invalid' //eslint-disable-line @typescript-eslint/no-explicit-any
			) {
				setIsLastNameError(isError);
				return;
			}
			if (
				isError &&
				(error as any)?.message === 'You are too young' //eslint-disable-line @typescript-eslint/no-explicit-any
			) {
				setIsDateOfBirthError(isError);
				return;
			}
			setFakeError(isError);
		}
	}, [isError]);

	const handleIsConfirmModalOpen = () => {
		setIsConfirmModalOpen(!isConfirmModalOpen);
	};

	const handleBackClick = async () => {
		sessionStorage.clear();
		await mutateClearAsync();
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value);
		setIsLastNameError(false);
		setFakeError(false);
		e.target.value.length >= 1
			? setLastNameRequired(true)
			: setLastNameRequired(false);
	};

	const handleMiddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setMiddleName(e.target.value);
		setFakeError(false);
		e.target.value.length >= 1
			? setMiddleNameRequired(true)
			: setMiddleNameRequired(false);
	};

	const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value);
		setIsFirstNameError(false);
		setFakeError(false);
		e.target.value.length >= 1
			? setIsFirstNameRequired(true)
			: setIsFirstNameRequired(false);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryChange = (value: { code: string; imgUrl: string }) => {
		setCountry({
			code: value.code,
			imgUrl: value.imgUrl
		});
	};

	const handleDateOfBirthChange = (
		e: string | ChangeEvent<HTMLInputElement>
	) => {
		if (typeof e === 'string') {
			setIsDateOfBirthError(false);
			setFakeError(false);
			setDateOfBirth(e);
			e.length >= 1
				? setDateOfBirthRequired(true)
				: setDateOfBirthRequired(false);
		}
	};

	const handleLastNameClearClick = () => {
		setLastName('');
		setLastNameRequired(false);
	};

	const handleFirstNameClearClick = () => {
		setFirstName('');
		setIsFirstNameRequired(false);
	};

	const handleMiddleNameClearClick = () => {
		setMiddleName('');
		setMiddleNameRequired(false);
	};

	const handleDateOfBirthClearClick = () => {
		setDateOfBirth('');
		setDateOfBirthRequired(false);
	};

	const handleSubmit = async () => {
		mutateAsync({
			wState: wState,
			firstName: firstName,
			middleName: middleName,
			lastName: lastName,
			dateOfBirth: dateOfBirth,
			citizenship: country?.code as string
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<ConfirmRegistrationModal
					isLoading={isClearLoading}
					isModalOpen={isConfirmModalOpen}
					handleIsModalOpen={handleIsConfirmModalOpen}
					handleConfirm={handleBackClick}
					text={t('startRegistrationAgainMain') as string}
				/>
				<CountryModal
					countries={countries.value}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleSelectClick}
					handleItemClick={handleCountryChange}
				/>
				<h1 className={'verificationLogoText'}>
					{t('verificationLogoText')}
				</h1>
				<CustomInput
					isError={isFirstNameError}
					isRequired={isFirstNameRequired}
					type={'text'}
					id={'firstName'}
					label={t('registrationName')}
					value={firstName}
					onChange={handleFirstNameChange}
					className={'verificationLastName'}
					onClearClick={handleFirstNameClearClick}
				/>
				{isFirstNameError ? (
					<h1 className={'registrationEmailError'}>
						{
							(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					''
				)}
				<CustomInput
					isRequired={isMiddleNameRequired}
					type={'text'}
					id={'middleName'}
					label={t('verificationMiddleName')}
					value={middleName}
					onChange={handleMiddleNameChange}
					className={'verificationLastName'}
					onClearClick={handleMiddleNameClearClick}
				/>
				<CustomInput
					isError={isLastNameError}
					isRequired={isLastNameRequired}
					type={'text'}
					id={'lastName'}
					label={t('verificationLastName')}
					value={lastName}
					onChange={handleLastNameChange}
					className={'verificationLastName'}
					onClearClick={handleLastNameClearClick}
				/>
				{isLastNameError ? (
					<h1 className={'registrationEmailError'}>
						{
							(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					''
				)}
				<CustomInput
					isError={isDateOfBirthError}
					isRequired={isDateOfBirthRequired}
					type={'text'}
					id={'dateOfBirth'}
					label={t('verificationDateOfBirth')}
					value={dateOfBirth}
					onChange={handleDateOfBirthChange}
					className={'verificationLastName'}
					onClearClick={handleDateOfBirthClearClick}
					placeHolder={locale === 'en' ? 'dd.mm.yyyy' : 'дд.мм.гггг'}
					isDateOfBirth
				/>
				{isDateOfBirthError ? (
					<h1 className={'registrationEmailError'}>
						{
							(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					''
				)}
				<CustomInput
					isRequired={
						country?.code != '' && country?.code != undefined
					}
					type={'text'}
					id={'countryNameSelect'}
					label={t('verificationCitizenship')}
					value={country?.code as string}
					onClearClick={handleSelectClick}
					className={'verificationLastName'}
					isSelectCode
					imgUrl={country?.imgUrl}
				/>
				{fakeError ? (
					<h1 className={'registrationEmailError'}>
						{
							(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					''
				)}
				<CustomButton
					isLoading={isPending}
					isDisabled={
						lastName.length == 0 ||
						dateOfBirth.length < 10 ||
						country?.code ===
							(t('verificationChooseCountry') as string)
					}
					type={'submit'}
					text={t('registrationContinue') as string}
					onClick={handleSubmit}
					className={'verificationLastName'}
				/>
				<div className={'divider'}>
					<Divider className={'divider'} />
				</div>
				<CustomButton
					isText={true}
					text={t('startRegistrationAgain') as string}
					onClick={handleIsConfirmModalOpen}
					className={'startButton'}
				/>
			</div>
		</div>
	);
};

export default VerificationLayout;

import { useContext, useEffect, useState } from 'react';
import { PinInput } from '../../common/inputs';
import { CustomButton } from '../../common/buttons';
import { ROUTES } from '../../../types/routes';
import { TWStateInfo } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { useLoginWithTwoFAMutation } from '../../../data/mutations/login';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { usePinInput } from 'react-pin-input-hook';
import { ParamContext } from '../../../data/param.provider';

const LoginOTPLayout = () => {
	const { wState } = useContext(ParamContext);

	const [sessionWState, setSessionWState] = useState<TWStateInfo | undefined>(
		undefined
	);

	const [sessionIdentityType, setSessionIdentityType] = useState<string>('');

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				login({
					wState: wState,
					email: sessionStorage.getItem('email') as string,
					password: sessionStorage.getItem('password') as string,
					twofa: {
						code: values,
						identityType: 'Email'
					},
					f2xUserAgent: sessionWState?.f2xUserAgent as string
				});
			}
		},
		type: 'numeric',
		placeholder: ''
	});

	const navigate = useCustomNavigate();

	const { isError, error, login, isPending } = useLoginWithTwoFAMutation(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	useEffect(() => {
		setSessionIdentityType(
			sessionStorage.getItem('loginIdentityType') as string
		);
		setSessionWState(
			JSON.parse(
				sessionStorage.getItem('wStateInfo') as string
			) as TWStateInfo
		);
	}, []);

	const handleBackClickButton = () => {
		navigate(ROUTES.LOGIN.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CustomButton
					isBack={true}
					onClick={handleBackClickButton}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('authenticationText')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t(
						sessionIdentityType === 'email'
							? 'otpCodeEmailDescription'
							: 'otpCodePhoneDescription'
					)}
				</h1>
				<div className={'codeInputBlock'}>
					<PinInput isLoading={isPending} isError={fakeError} fields={fields} />
					{fakeError ? (
						<h1 className={'errorText'}>
							{
								(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={'errorText'} />
					)}
				</div>
			</div>
		</div>
	);
};

export default LoginOTPLayout;

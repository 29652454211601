import SumsubWebSdk from "@sumsub/websdk-react";
import { useEffect, useState } from "react";
import { CustomButton } from "../../common/buttons";
import { useSumsubClearMutation } from "../../../data/mutations/verification";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { useTranslation } from "react-i18next";

const VerificationSumSubLayout = () => {
	const [sumSubAccessToken, setSumSubAccessToken] = useState<string>("");

	const {t} = useTranslation();

	const navigate = useCustomNavigate();

	const { isError, isPending, mutateAsync, error } = useSumsubClearMutation(navigate);

	useEffect(() => {
		const sumsubInfo = JSON.parse(sessionStorage.getItem("sumsubInfo") as string);
		if (sumsubInfo) {
			setSumSubAccessToken(sumsubInfo?.widgetConfiguration?.accessToken);
		}
	}, []);

	const expirationHandler = () => {
		console.log("");
	};

	if (sumSubAccessToken === "") {
		return <></>;
	}

	const handleBackClick = () => {
		mutateAsync();
	};

	return (
		<div className={"component"}>
			<SumsubWebSdk
				accessToken={sumSubAccessToken}
				expirationHandler={expirationHandler}
			/>
			{isError ? (
				<h1 className={"registrationEmailError"}>
					{
						(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
					}
				</h1>
			) : (
				""
			)}
			<CustomButton
				isLoading={isPending}
				isText={true}
				text={t("startRegistrationAgain") as string}
				onClick={handleBackClick}
				className={"backButton"}
			/>
		</div>
	);
};

export default VerificationSumSubLayout;
